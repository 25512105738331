




































import {Component, Vue} from 'vue-property-decorator';
import CourseTime from '@/components/CourseTime.vue'
import {getPtList} from '@/api/course'
import {CourseDto} from '@/dto/CourseDto';

@Component({
  components: {
    CourseTime
  }
})
export default class UserPtIndex extends Vue {
  private courses: any = {
    items: new Array<CourseDto>()
  }

  private async activated() {
    await this.getList()
  }

  private async getList() {
    this.courses = await getPtList()
  }

  private async handleClickA(item: any) {
    await this.$router.push({
      path: '/course/pt-info',
      query: {
        courseId: item.id
      }
    })
  }

  private async handleClickCoach(item: any) {
    await this.$router.push({
      path: '/course/coach-info',
      query: {
        coachId: item.id
      }
    })
  }
}

