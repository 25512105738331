
























import {Component, Prop, Vue} from 'vue-property-decorator';
import {getWeek} from '@/utils/common'

@Component({})
export default class CourseTime extends Vue {
  @Prop({default: () => true}) private showDate!: boolean

  private currentDate = ''
  private dates: any[] = []
  private queryDate = ''

  private mounted() {
    this.getCurrentDate()
    this.ge7Days()
  }

  private getCurrentDate() {
    const now = new Date();
    const month = (now.getMonth() + 1).toString().padStart(2, '0')
    const date = (now.getDate()).toString().padStart(2, '0')
    this.queryDate = `${month}.${date}`
    this.currentDate = `${month}月${date}日 星期${getWeek(now.getDay())}`
  }

  private handleClickOpen() {
    this.$router.push('/open')
  }

  private async handleClickDate(item: any) {
    this.queryDate = item.date
    this.$emit('getList', this.queryDate)
  }

  private ge7Days() {
    this.dates = []
    for (let i = 0; i < 7; i++) {
      const now = new Date()
      const newDate = new Date(now.setDate(now.getDate() + i))
      const month = (newDate.getMonth() + 1).toString().padStart(2, '0')
      const date = (newDate.getDate()).toString().padStart(2, '0')
      let week = `周${getWeek(newDate.getDay())}`
      if (i === 0) {
        week = '今天'
      }
      this.dates.push({
        date: `${month}.${date}`,
        week
      })
    }
  }
}
