import request from '@/utils/request'
import {CourseDto, CoursePtBuyDto} from '@/dto/CourseDto'

const url = '/keep-fit/course/'

// 获取团体课
export function getCourseByDateListAsync(dt: string): Promise<CourseDto[]> {
  return new Promise((resolve, reject) => {
    request({
      url: `${url}courseByDateList`,
      method: 'get',
      params: {
        dt
      }
    }).then((res) => resolve(res.data)).catch((error) => reject(error))
  })
}

// 获取课程详细信息
export function getCourseByIdAsync(id: string | Array<string | null>): Promise<CourseDto> {
  return new Promise<CourseDto>((resolve, reject) => {
    request({
      url: `${url}${id}`,
      method: 'get'
    }).then((res) => resolve(res.data)).catch((error) => reject(error))
  })
}

// 获取教练团课内容
export function getCourseByCoachIdAsync(dt: string, state?: number) {
  return new Promise((resolve, reject) => {
    request({
      url: `${url}course-list-coach`,
      method: 'get',
      params: {
        dt,
        state
      }
    }).then((res) => resolve(res.data)).catch((error) => reject(error))
  })
}

// 获取教练团课内容
export function getCourseCoachByIdAsync(id: string | Array<string | null>): Promise<CourseDto> {
  return new Promise<CourseDto>((resolve, reject) => {
    request({
      url: `${url}${id}/by-coach`,
      method: 'get'
    }).then((res) => resolve(res.data)).catch((error) => reject(error))
  })
}

// 教练更新是否开课
export function updateCourseAsync(id: string | Array<string | null>, state: number) {
  return new Promise((resolve, reject) => {
    request({
      url: `${url}${id}/update-course?state=${state}`,
      method: 'post'
    }).then((res) => resolve(res.data)).catch((error) => reject(error))
  })
}

export function getPtList(): Promise<CourseDto[]> {
  return new Promise<CourseDto[]>((resolve, reject) => {
    request({
      url: `${url}pt-list`,
      method: 'get'
    }).then((res) => resolve(res.data)).catch((error) => reject(error))
  })
}

// 获取私教课程详细信息
export function getPtCourseByIdAsync(id: string | Array<string | null>): Promise<CourseDto> {
  return new Promise<CourseDto>((resolve, reject) => {
    request({
      url: `${url}${id}/pt-info`,
      method: 'get'
    }).then((res) => resolve(res.data)).catch((error) => reject(error))
  })
}

// 获取私教课程购买页详细信息
export function getPtBuyCourseByIdAsync(courseId: string | Array<string | null>, coachId: string | Array<string | null>): Promise<CoursePtBuyDto> {
  return new Promise<CoursePtBuyDto>((resolve, reject) => {
    request({
      url: `${url}pt-buy-info`,
      method: 'get',
      params: {
        courseId,
        coachId
      }
    }).then((res) => resolve(res.data)).catch((error) => reject(error))
  })
}
